import { AimToastItemComponent } from './aim-toast-item/aim-toast-item.component';
import { AimToastOption, ToastState, ToastStateType } from '@aimmo/design-system/aim-toast/model/src';
import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class AimToast {

  constructor(private matSnackBar: MatSnackBar) {
  }

  public openPositive(text: string, option?: AimToastOption): MatSnackBarRef<AimToastItemComponent> {
    return this.open(ToastState.positive, text, option?.duration, option?.horizontal, option?.vertical, option?.actionText, option?.useCloseButton);
  }

  public openBasic(text: string, option?: AimToastOption): MatSnackBarRef<AimToastItemComponent> {
    return this.open(ToastState.basic, text, option?.duration, option?.horizontal, option?.vertical, option?.actionText, option?.useCloseButton);
  }

  public openNegative(text: string, option?: AimToastOption): MatSnackBarRef<AimToastItemComponent> {
    return this.open(ToastState.negative, text, option?.duration, option?.horizontal, option?.vertical, option?.actionText, option?.useCloseButton);
  }

  private open(state: ToastStateType, text: string, duration = 3000,
               horizontal: MatSnackBarHorizontalPosition = 'center', vertical: MatSnackBarVerticalPosition = 'top',
               actionText?, useCloseButton = false): MatSnackBarRef<AimToastItemComponent> {
    return this.matSnackBar.openFromComponent(AimToastItemComponent, {
      data: {
        state, text, actionText, useCloseButton
      },
      duration,
      horizontalPosition: horizontal,
      verticalPosition: vertical,
      panelClass: 'aimmo-custom-snack-bar',
    });
  }
}

