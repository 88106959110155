import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

export const ToastState = {
  basic: 'basic',
  positive: 'positive',
  negative: 'negative',
} as const;
export type ToastStateType = keyof typeof ToastState;

export enum ToastIcon {
  default = '',
  positive = 'check',
  negative = 'error',
}

export interface AimToastOption {
  duration?: number;
  horizontal?: MatSnackBarHorizontalPosition;
  vertical?: MatSnackBarVerticalPosition;
  actionText?: string;
  useCloseButton?: boolean;
}
