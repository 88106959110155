import { AimIconButtonComponent } from '@aimmo/design-system/aim-icon-button/src';
import { AimIconComponent } from '@aimmo/design-system/aim-icon/src';
import { ToastIcon, ToastState, ToastStateType } from '@aimmo/design-system/aim-toast/model/src';
import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { AimButtonHostingDirective } from '../../../shared/directives/aim-button-hosting.directive';

@Component({
  selector: 'aim-toast-item',
  templateUrl: 'aim-toast-item.component.html',
  styleUrls: ['aim-toast-item.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    AimIconComponent,
    AimButtonHostingDirective,
    AimIconButtonComponent,
  ],
})

export class AimToastItemComponent {
  public readonly ToastState = ToastState;

  constructor(
    public snackBarRef: MatSnackBarRef<AimToastItemComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: {
      state: ToastStateType,
      text: string,
      actionText: string,
      useCloseButton: boolean
    },
  ) {
  }

  public get icon(): ToastIcon {
    if (this.data.state === ToastState.positive) {
      return ToastIcon.positive;
    } else if (this.data.state === ToastState.negative) {
      return ToastIcon.negative;
    } else {
      return ToastIcon.default;
    }
  }

  public onClose(): void {
    this.snackBarRef.dismiss();
  }

  public onAction(): void {
    this.snackBarRef.dismissWithAction();
  }
}
